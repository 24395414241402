<template>
  <div id="slide-wrapper">
    <div id="mobile-slide-container">
      <VueSlickCarousel
        :arrows="false"
        :dots="false"
        :infinte="false"
        :speed="600"
        :slidesToShow="1"
        :slidesToScroll="1"
        :centerMode="true"
        centerPadding="0"
      >
        <div
          class="slider-item"
          v-for="mImg in mobileImages"
          :key="mImg.src"
          :id="mImg.id"
        >
          <div class="position-relative">
            <img :src="mImg.imgSrc" />
            <template v-for="btn in mImg.listBtns">
              <a
                :id="btn.id"
                class="img-link"
                :href="btn.link"
                :key="btn.id"
                :style="[btn.style]"
                target="_blank"
              ></a>
            </template>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
    <div id="desktop-slide-container">
      <VueSlickCarousel
        :arrows="true"
        :dots="false"
        :infinte="false"
        :focusOnSelect="true"
        :speed="600"
        :slidesToShow="1"
        :slidesToScroll="1"
        :centerMode="true"
        centerPadding="0"
      >
        <div
          class="slider-item"
          v-for="dImg in desktopImages"
          :key="dImg.src"
          :id="dImg.id"
        >
          <div class="position-relative">
            <img :src="dImg.imgSrc" />
            <template v-for="btn in dImg.listBtns">
              <a
                class="img-link"
                :href="btn.link"
                :key="btn.id"
                :style="[btn.style]"
                target="_blank"
              ></a>
            </template>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: { VueSlickCarousel },
  data() {
    return {};
  },
  computed: {
    mobileImages() {
      return [
        {
          id: "start-mobile",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0002.jpg",
        },
        {
          id: "2",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0003.jpg",
        },
        {
          id: "3",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0004.jpg",
        },
        {
          id: "4",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0005.jpg",
          listBtns: [
            {
              id: "ma_3_1",
              style: {
                left: "54%",
                top: "20%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/gantt/?variation_id=13427",
            },
            {
              id: "ma_3_2",
              style: {
                left: "54%",
                top: "40.5%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/piper/?variation_id=37929",
            },
            {
              id: "ma_3_3",
              style: {
                left: "54%",
                bottom: "39%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link:
                "https://artisans.vn/san-pham/belling-ham-leather-sofa/?variation_id=35346",
            },
            {
              id: "ma_3_4",
              style: {
                left: "54%",
                bottom: "20.5%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/hansen/?variation_id=37941",
            },
          ],
        },
        {
          id: "5",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0006.jpg",
          listBtns: [
            {
              id: "ma_4_1",
              style: {
                left: "82%",
                top: "20%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/gantt/?variation_id=13427",
            },
          ],
        },
        {
          id: "6",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%204_1.jpg",
          listBtns: [
            {
              id: "ma_4_2",
              style: {
                right: "10.5%",
                bottom: "34.5%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/baker/?variation_id=3218",
            },
          ],
        },
        {
          id: "7",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%205.jpg",
          listBtns: [
            {
              id: "ma_5_1",
              style: {
                left: "33%",
                top: "12%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/stuart/?variation_id=12370",
            },
            {
              id: "ma_5_3",
              style: {
                left: "52.5%",
                bottom: "48.5%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/teddy/?variation_id=13381",
            },
          ],
        },
        {
          id: "8",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%205_1.jpg",
          listBtns: [
            {
              id: "ma_5_2",
              style: {
                left: "56.5%",
                top: "0",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/stuart/?variation_id=13241",
            },
          ],
        },
        {
          id: "9",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%206.jpg",
        },
        {
          id: "10",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%206_1.jpg",

          listBtns: [
            {
              id: "ma_6_1",
              style: {
                left: "31%",
                top: "20%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/orphan-5/?variation_id=10649",
            },
            {
              id: "ma_6_2",
              style: {
                left: "31%",
                top: "42%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link:
                "https://artisans.vn/san-pham/ripples-wall-art/?variation_id=11310",
            },
            {
              id: "ma_6_3",
              style: {
                left: "31%",
                top: "73%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/scott-2/?variation_id=37950",
            },
          ],
        },
        {
          id: "11",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%207.jpg",
          listBtns: [
            {
              id: "ma_7_2",
              style: {
                left: "32%",
                top: "41%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/whistler/?variation_id=32769",
            },
          ],
        },
        {
          id: "12",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%207_1.jpg",
          listBtns: [
            {
              id: "ma_7_1_1",
              style: {
                left: "50%",
                top: "6%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/luxury/?variation_id=14046",
            },
          ],
        },
        {
          id: "13",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%208.jpg",
          listBtns: [
            {
              id: "ma_8_2",
              style: {
                left: "71.5%",
                bottom: "21.5%",
              },
              link:
                "https://artisans.vn/san-pham/vanessa-2/?variation_id=10835 ",
            },
          ],
        },
        {
          id: "14",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%208_1.jpg",
          listBtns: [
            {
              id: "ma_8_1",
              style: { left: "70%", top: "74%" },
              link: "https://artisans.vn/san-pham/vanessa/?variation_id=10826 ",
            },
            {
              id: "ma_8_3",
              style: {
                left: "12.5%",
                top: "36.5%",
              },
              link: "https://artisans.vn/san-pham/duo/?variation_id=5398 ",
            },
          ],
        },
        {
          id: "15",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%209.jpg",
        },
        {
          id: "16",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%209_1.jpg",
          listBtns: [
            {
              id: "ma_9_1",
              style: { right: "38.5%", top: "53.5%" },
              link:
                "https://artisans.vn/san-pham/chesterfiel/?variation_id=5142",
            },
            {
              id: "ma_9_2",
              style: { right: "28.5%", bottom: "0%" },
              link:
                "https://artisans.vn/danh-muc/cua-hang-trang-tri-noi-that/rugs/?zarsrc=1303",
            },
          ],
        },
        {
          id: "17",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2010.jpg",
        },
        {
          id: "18",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2010_1.jpg",
          listBtns: [
            {
              id: "ma_10_1",
              style: { right: "30.5%", top: "52%" },
              link: "https://artisans.vn/san-pham/orphan-2/?variation_id=3181",
            },
            {
              id: "ma_10_2",
              style: {
                right: "47%",
                bottom: "22%",
              },
              link:
                "https://artisans.vn/san-pham/shagreen-coffee-table/?variation_id=4513",
            },
          ],
        },
        {
          id: "19",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2011.jpg",
          listBtns: [
            {
              id: "ma_11_1",
              style: {
                left: "11%",
                top: "89.5%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/brittany-angle/?variation_id=4178",
            },
          ],
        },
        {
          id: "20",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2011_1.jpg",
          listBtns: [],
        },
        {
          id: "21",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2012.jpg",
          listBtns: [
            {
              id: "ma_12_1",
              style: {
                left: "50.5%",
                top: "56%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/graham/?variation_id=4912",
            },
          ],
        },
        {
          id: "22",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2012_1.jpg",
          listBtns: [
            {
              id: "ma_12_2",
              style: {
                left: "57.5%",
                top: "60%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/amaro/?variation_id=4926",
            },
          ],
        },
        {
          id: "23",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2013.jpg",
        },
        {
          id: "24",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2013_1.jpg",
        },
        {
          id: "25",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0022.jpg",
          listBtns: [
            {
              id: "ma_14_1",
              style: {
                left: "20.5%",
                top: "48%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/zola/?variation_id=14970",
            },
          ],
        },
        {
          id: "26",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0023.jpg",
          listBtns: [
            {
              id: "ma_14_2",
              style: {
                left: "43.5%",
                top: "71%",
                width: "6%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/mojo/?variation_id=13104",
            },
          ],
        },
        {
          id: "27",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2015.jpg",
          listBtns: [
            // row product 1
            {
              id: "ma_15_1",
              style: { left: "10%", top: "16%" },
              link:
                "https://artisans.vn/san-pham/green-cactus/?variation_id=8028",
            },
            {
              id: "ma_15_2",
              style: { left: "55.5%", top: "16%" },
              link:
                "https://artisans.vn/san-pham/saguaro-ceramic-cactus/?variation_id=38301",
            },
            // row product 2
            {
              id: "ma_15_4",
              style: { left: "10%", top: "41.5%" },
              link: "https://artisans.vn/san-pham/elaphants/?variation_id=7097",
            },
            {
              id: "ma_15_5",
              style: {
                left: "55.5%",
                top: "41.5%",
              },
              link: "https://artisans.vn/san-pham/camel/?variation_id=13746",
            },
            // row product 3
            {
              id: "ma_15_8",
              style: { left: "10%", bottom: "24%" },
              link: "https://artisans.vn/san-pham/horse-3/?variation_id=17241",
            },
            {
              id: "ma_15_9",
              style: {
                left: "55.5%",
                bottom: "24%",
              },
              link:
                "https://artisans.vn/san-pham/elephant-family/?variation_id=37970",
            },
          ],
        },
        {
          id: "28",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2015_1.jpg",
          listBtns: [
            {
              id: "ma_15_3",
              style: { left: "0.5%", top: "16%" },
              link:
                "https://artisans.vn/san-pham/three-bud-ceramic-cactus/?variation_id=38286",
            },
            {
              id: "ma_15_7",
              style: { right: "45%", top: "16%" },
              link: "https://artisans.vn/san-pham/statue-2/?variation_id=15680",
            },
            {
              id: "ma_15_6",
              style: {
                left: "0.5%",
                top: "41.5%",
              },
              link: "https://artisans.vn/san-pham/animals-2/?variation_id=37962",
            },
            {
              id: "ma_15_10",
              style: {
                left: "0.5%",
                bottom: "24%",
              },
              link:
                "https://artisans.vn/san-pham/bethany-marble-box/?variation_id=8429",
            },
            {
              id: "ma_15_11",
              style: {
                right: "45%",
                bottom: "24%",
              },
              link:
                "https://artisans.vn/san-pham/candle-holder/?variation_id=38044",
            },
          ],
        },
        {
          id: "29",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0026.jpg",
          listBtns: [
            // row product 1
            {
              id: "ma_16_1",
              style: { left: "10%", top: "14%" },
              link: "https://artisans.vn/san-pham/vase-4/?variation_id=17750",
            },
            {
              id: "ma_16_2",
              style: { left: "54.6%", top: "14%" },
              link: "https://artisans.vn/san-pham/vase-4/?variation_id=17751",
            },

            // row product 2
            {
              id: "ma_16_5",
              style: { left: "10%", top: "39.5%" },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9019",
            },
            {
              id: "ma_16_6",
              style: {
                left: "55.5%",
                top: "39.5%",
              },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9019",
            },

            // row product 3
            {
              id: "ma_16_9",
              style: {
                left: "10%",
                bottom: "25.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
            {
              id: "ma_16_10",
              style: {
                left: "55.5%",
                bottom: "25.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
          ],
        },
        {
          id: "30",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0027.jpg",
          listBtns: [
            {
              id: "ma_16_3",
              style: { left: "0.5%", top: "14%" },
              link:
                "https://artisans.vn/san-pham/cactus-vase/?variation_id=38308",
            },
            {
              id: "ma_16_4",
              style: { right: "45%", top: "14%" },
              link:
                "https://artisans.vn/san-pham/cactus-vase/?variation_id=38308",
            },
            {
              id: "ma_16_7",
              style: {
                left: "0.5%",
                top: "39.5%",
              },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9020",
            },

            {
              id: "ma_16_8",
              style: { right: "46%", top: "39.5%" },
              link:
                "https://artisans.vn/san-pham/green-leaf-jar/?variation_id=8010",
            },
            {
              id: "ma_16_11",
              style: {
                left: "0.5%",
                bottom: "25.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
            {
              id: "ma_16_12",
              style: {
                right: "45%",
                bottom: "25.5%",
              },
              link:
                "https://artisans.vn/san-pham/green-leaf-jar/?variation_id=8010",
            },
          ],
        },
        {
          id: "31",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2017.jpg",

          listBtns: [
            // row product 1
            {
              id: "ma_17_1",
              style: { left: "7.5%", top: "14.5%" },
              link: "https://artisans.vn/san-pham/sika-deer/?variation_id=35401",
            },
            {
              id: "ma_17_2",
              style: {
                left: "38.5%",
                top: "14.5%",
              },
              link: "https://artisans.vn/san-pham/ice-cream/?variation_id=35532",
            },
            {
              id: "ma_17_3",
              style: { left: "68.5%", top: "14.5%" },
              link: "https://artisans.vn/san-pham/rose/?variation_id=20878",
            },

            // row product 2
            {
              id: "ma_17_7",
              style: {
                left: "7.5%",
                top: "40.5%",
              },
              link:
                "https://artisans.vn/san-pham/three-leaves/?variation_id=20854",
            },
            {
              id: "ma_17_8",
              style: {
                left: "37.5%",
                top: "40.5%",
              },
              link:
                "https://artisans.vn/san-pham/purple-rose/?variation_id=35553",
            },
            {
              id: "ma_17_9",
              style: {
                left: "67.5%",
                top: "40.5%",
              },
              link:
                "https://artisans.vn/san-pham/the-statue-of-liberty/?variation_id=23794",
            },
            {
              id: "ma_17_12",
              style: { left: "8%", bottom: "25%" },
              link:
                "https://artisans.vn/san-pham/gangster-dogs/?variation_id=33477",
            },
          ],
        },
        {
          id: "32",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2017_1.jpg",

          listBtns: [
            {
              id: "ma_17_4",
              style: { left: "-1%", top: "14.5%" },
              link:
                "https://artisans.vn/san-pham/pineapple-2/?variation_id=20866",
            },
            {
              id: "ma_17_5",
              style: {
                left: "28.5%",
                top: "14.5%",
              },
              link:
                "https://artisans.vn/san-pham/buffaloes-in-field-2/?variation_id=34411",
            },
            {
              id: "ma_17_6",
              style: { left: "58.5%", top: "14.5%" },
              link:
                "https://artisans.vn/san-pham/return-to-nature/?variation_id=35390",
            },
            {
              id: "ma_17_10",
              style: {
                left: "-1%",
                top: "40.5%",
              },
              link:
                "https://artisans.vn/san-pham/london-bridge/?variation_id=23760",
            },
            {
              id: "ma_17_11",
              style: {
                left: "33.5%",
                top: "40.5%",
              },
              link:
                "https://artisans.vn/san-pham/buffaloes-in-field-2/?variation_id=34411",
            },
            {
              id: "ma_17_13",
              style: { left: "-1%", bottom: "25%" },
              link:
                "https://artisans.vn/san-pham/clorful-cats/?variation_id=33434",
            },
          ],
        },
        {
          id: "33",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2018.jpg",
          listBtns: [
            {
              id: "ma_18_1",
              style: { left: "18%", top: "38.5%" },
              link: "https://artisans.vn/san-pham/morella-stacking-armchair/?variation_id=6704",
            },
            {
              id: "ma_18_2",
              style: { left: "57.5%", top: "27%" },
              link:
                "https://artisans.vn/san-pham/morella-round-table/?variation_id=6653 ",
            },
          ],
        },
        {
          id: "34",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2018_1.jpg",
          listBtns: [
            {
              id: "ma_18_3",
              style: {
                right: "27.5%",
                top: "26.5%",
              },
              link:
                "https://artisans.vn/san-pham/morella-square-table/?variation_id=14017",
            },
            {
              id: "ma_18_4",
              style: {
                right: "6.5%",
                bottom: "21.5%",
              },
              link: "https://artisans.vn/san-pham/plantagoo/?variation_id=27861",
            },
          ],
        },
        {
          id: "35",
          imgSrc:
            "https://artisans.vn/wp-content/themes/igitech/images/A5/0032.jpg",
        },
        {
          id: "36",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A5/A5_Brochure%20Instore%2019_1.jpg",
        },
      ];
    },

    // Object image for desktop
    desktopImages() {
      return [
        {
          id: "start",
          // imgSrc:
          //   "https://artisans.vn/wp-includes/images/brochure/bia%20trang%20den.jpg"
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/bia%20mau.jpg",
        },
        // Page number 1
        {
          id: 1,
          name: "page1",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%200119.jpg",
          listBtns: [],
        },

        // Page number 2
        {
          id: 2,
          name: "page2",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001153.jpg",
          listBtns: [],
        },

        // Page number 3
        {
          id: 3,
          name: "page3",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001154.jpg",
          listBtns: [
            {
              id: "a_3_1",
              style: {
                left: "40%",
                top: "22%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/gantt/?variation_id=13427",
            },
            {
              id: "a_3_2",
              style: {
                left: "87.5%",
                top: "23.5%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/piper/?variation_id=37929",
            },
            {
              id: "a_3_3",
              style: {
                left: "44.5%",
                bottom: "35%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link:
                "https://artisans.vn/san-pham/belling-ham-leather-sofa/?variation_id=35346",
            },
            {
              id: "a_3_4",
              style: {
                left: "87.5%",
                bottom: "35.5%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/hansen/?variation_id=37941",
            },
          ],
        },

        // Page number 4
        {
          id: 4,
          name: "page4",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001155.jpg",
          listBtns: [
            {
              id: "a_4_1",
              style: {
                left: "41%",
                top: "21%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/gantt/?variation_id=13427",
            },
            {
              id: "a_4_2",
              style: {
                left: "91.5%",
                bottom: "34.5%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/baker/?variation_id=3218",
            },
          ],
        },

        // Page number 5
        {
          id: 5,
          name: "page5",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001156.jpg",
          listBtns: [
            {
              id: "a_5_1",
              style: {
                left: "17%",
                top: "12%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/stuart/?variation_id=12370",
            },
            {
              id: "a_5_2",
              style: {
                left: "80.5%",
                top: "0",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/stuart/?variation_id=13241",
            },
            {
              id: "a_5_3",
              style: {
                left: "27.5%",
                bottom: "47.5%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/teddy/?variation_id=13381",
            },
          ],
        },

        // Page number 6
        {
          id: 6,
          name: "page6",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001196.jpg",
          listBtns: [
            {
              id: "a_6_1",
              style: {
                left: "67%",
                top: "20%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/orphan-5/?variation_id=10649",
            },
            {
              id: "a_6_2",
              style: {
                left: "67%",
                top: "42%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link:
                "https://artisans.vn/san-pham/ripples-wall-art/?variation_id=11310",
            },
            {
              id: "a_6_3",
              style: {
                left: "67%",
                top: "73%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/scott-2/?variation_id=37950",
            },
          ],
        },

        // Page number 7
        {
          id: 7,
          name: "page7",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001197.jpg",
          listBtns: [
            {
              id: "a_7_1",
              style: {
                left: "71%",
                top: "2%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/luxury/?variation_id=14046",
            },
            {
              id: "a_7_2",
              style: {
                left: "16%",
                top: "36%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/whistler/?variation_id=32769",
            },
          ],
        },

        // Page number 8
        {
          id: 8,
          name: "page8",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001159.jpg",
          listBtns: [
            {
              id: "a_8_1",
              style: { left: "82%", top: "73%" },
              link: "https://artisans.vn/san-pham/vanessa/?variation_id=10826 ",
            },
            {
              id: "a_8_2",
              style: {
                left: "32.5%",
                bottom: "22.5%",
              },
              link:
                "https://artisans.vn/san-pham/vanessa-2/?variation_id=10835 ",
            },
            {
              id: "a_8_3",
              style: {
                left: "53.5%",
                top: "35.5%",
              },
              link: "https://artisans.vn/san-pham/duo/?variation_id=5398 ",
            },
          ],
        },

        // Page 9
        {
          id: 9,
          name: "page9",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%2001199.jpg",
          listBtns: [
            {
              id: "a_9_1",
              style: { right: "16.5%", top: "53.5%" },
              link:
                "https://artisans.vn/san-pham/chesterfiel/?variation_id=5142",
            },
            {
              id: "a_9_2",
              style: { right: "11.2%", bottom: "0%" },
              link:
                "https://artisans.vn/danh-muc/cua-hang-trang-tri-noi-that/rugs/?zarsrc=1303",
            },
          ],
        },

        // Page 10
        {
          id: 10,
          name: "page10",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011511.jpg",
          listBtns: [
            {
              id: "a_10_1",
              style: { right: "12.5%", top: "53%" },
              link: "https://artisans.vn/san-pham/orphan-2/?variation_id=3181",
            },
            {
              id: "a_10_2",
              style: {
                right: "21%",
                bottom: "22%",
              },
              link:
                "https://artisans.vn/san-pham/shagreen-coffee-table/?variation_id=4513",
            },
          ],
        },

        // Page 11
        {
          id: 11,
          name: "page11",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011512.jpg",
          listBtns: [
            {
              id: "a_11_1",
              style: {
                left: "5.5%",
                top: "89%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/brittany-angle/?variation_id=4178",
            },
          ],
        },

        // Page 12
        {
          id: 12,
          name: "page12",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011513.jpg",
          listBtns: [
            {
              id: "a_12_1",
              style: {
                left: "26.5%",
                top: "56%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/graham/?variation_id=4912",
            },
            {
              id: "a_12_2",
              style: {
                left: "78.5%",
                top: "60%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/amaro/?variation_id=4926",
            },
          ],
        },

        // Page 13
        {
          name: "page13",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011514.jpg",
          listBtns: [],
        },

        // Page 14
        {
          id: 14,
          name: "page14",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011515.jpg",
          listBtns: [
            {
              id: "a_14_1",
              style: {
                left: "10.5%",
                top: "48%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/zola/?variation_id=14970",
            },
            {
              id: "a_14_2",
              style: {
                left: "72.5%",
                top: "72%",
                width: "3%",
                height: "4%",
                opacity: "1",
                "background-image":
                  "url(https://artisans.vn/wp-includes/images/brochure/loading.gif)",
                "background-size": "20px",
                "background-repeat": "no-repeat",
                "background-position": "center",
              },
              link: "https://artisans.vn/san-pham/mojo/?variation_id=13104",
            },
          ],
        },

        // Page 15
        {
          id: 15,
          name: "page15",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011516.jpg",
          listBtns: [
            // row product 1
            {
              id: "a_15_1",
              style: { left: "2%", top: "16.5%" },
              link:
                "https://artisans.vn/san-pham/green-cactus/?variation_id=8028",
            },
            {
              id: "a_15_2",
              style: { left: "24%", top: "16.5%" },
              link:
                "https://artisans.vn/san-pham/saguaro-ceramic-cactus/?variation_id=38301",
            },
            {
              id: "a_15_3",
              style: { left: "46.5%", top: "16.5%" },
              link:
                "https://artisans.vn/san-pham/three-bud-ceramic-cactus/?variation_id=38286",
            },
            {
              id: "a_15_7",
              style: { right: "20%", top: "16.5%" },
              link: "https://artisans.vn/san-pham/statue-2/?variation_id=15680",
            },

            // row product 2
            {
              id: "a_15_4",
              style: { left: "2%", top: "42%" },
              link: "https://artisans.vn/san-pham/elaphants/?variation_id=7097",
            },
            {
              id: "a_15_5",
              style: {
                left: "23.5%",
                top: "42%",
              },
              link: "https://artisans.vn/san-pham/camel/?variation_id=13746",
            },
            {
              id: "a_15_6",
              style: {
                left: "46.5%",
                top: "42%",
              },
              link: "https://artisans.vn/san-pham/animals-2/?variation_id=37962",
            },

            // row product 3
            {
              id: "a_15_8",
              style: { left: "2%", bottom: "23%" },
              link: "https://artisans.vn/san-pham/horse-3/?variation_id=17241",
            },
            {
              id: "a_15_9",
              style: {
                left: "24%",
                bottom: "23%",
              },
              link:
                "https://artisans.vn/san-pham/elephant-family/?variation_id=37970",
            },
            {
              id: "a_15_10",
              style: {
                left: "46.5%",
                bottom: "23%",
              },
              link:
                "https://artisans.vn/san-pham/bethany-marble-box/?variation_id=8429",
            },
            {
              id: "a_15_11",
              style: {
                right: "20%",
                bottom: "23%",
              },
              link:
                "https://artisans.vn/san-pham/candle-holder/?variation_id=38044",
            },
          ],
        },

        // Page 16
        {
          id: 16,
          name: "page16",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011517.jpg",
          listBtns: [
            // row product 1
            {
              id: "a_16_1",
              style: { left: "3%", top: "14.5%" },
              link: "https://artisans.vn/san-pham/vase-4/?variation_id=17750",
            },
            {
              id: "a_16_2",
              style: { left: "25.6%", top: "14.5%" },
              link: "https://artisans.vn/san-pham/vase-4/?variation_id=17751",
            },
            {
              id: "a_16_3",
              style: { left: "46.5%", top: "14.5%" },
              link:
                "https://artisans.vn/san-pham/cactus-vase/?variation_id=38308",
            },
            {
              id: "a_16_4",
              style: { right: "20%", top: "14.5%" },
              link:
                "https://artisans.vn/san-pham/cactus-vase/?variation_id=38308",
            },

            // row product 2
            {
              id: "a_16_5",
              style: { left: "2%", top: "40.5%" },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9019",
            },
            {
              id: "a_16_6",
              style: {
                left: "24%",
                top: "40.5%",
              },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9019",
            },
            {
              id: "a_16_7",
              style: {
                left: "46.5%",
                top: "40.5%",
              },
              link: "https://artisans.vn/san-pham/vase-3/?variation_id=9020",
            },

            {
              id: "a_16_8",
              style: { right: "20%", top: "40.5%" },
              link:
                "https://artisans.vn/san-pham/green-leaf-jar/?variation_id=8010",
            },

            // row product 3
            {
              id: "a_16_9",
              style: {
                left: "2%",
                bottom: "24.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
            {
              id: "a_16_10",
              style: {
                left: "24.5%",
                bottom: "24.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
            {
              id: "a_16_11",
              style: {
                left: "46.5%",
                bottom: "24.5%",
              },
              link:
                "https://artisans.vn/san-pham/shaylee-vase/?variation_id=38017",
            },
            {
              id: "a_16_12",
              style: {
                right: "20%",
                bottom: "24.5%",
              },
              link:
                "https://artisans.vn/san-pham/green-leaf-jar/?variation_id=8010",
            },
          ],
        },

        // Page 17
        {
          id: 17,
          name: "page17",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011917.jpg",
          listBtns: [
            // row product 1
            {
              id: "a_17_1",
              style: { left: "2%", top: "15.5%" },
              link: "https://artisans.vn/san-pham/sika-deer/?variation_id=35401",
            },
            {
              id: "a_17_2",
              style: {
                left: "17%",
                top: "15.5%",
              },
              link: "https://artisans.vn/san-pham/ice-cream/?variation_id=35532",
            },
            {
              id: "a_17_3",
              style: { left: "32%", top: "15.5%" },
              link: "https://artisans.vn/san-pham/rose/?variation_id=20878",
            },
            {
              id: "a_17_4",
              style: { left: "46%", top: "15.5%" },
              link:
                "https://artisans.vn/san-pham/pineapple-2/?variation_id=20866",
            },

            {
              id: "a_17_5",
              style: {
                left: "61.5%",
                top: "15.5%",
              },
              link:
                "https://artisans.vn/san-pham/return-to-nature/?variation_id=35390",
            },

            {
              id: "a_17_6",
              style: {
                right: "13.5%",
                top: "15.5%",
              },
              link:
                "https://artisans.vn/san-pham/all-you-need-is-love/?variation_id=35542",
            },

            // row product 2
            {
              id: "a_17_7",
              style: { left: "2%", top: "41.5%" },
              link:
                "https://artisans.vn/san-pham/three-leaves/?variation_id=20854",
            },
            {
              id: "a_17_8",
              style: {
                left: "17%",
                top: "41.5%",
              },
              link:
                "https://artisans.vn/san-pham/purple-rose/?variation_id=35553",
            },
            {
              id: "a_17_9",
              style: {
                left: "32%",
                top: "41.5%",
              },
              link:
                "https://artisans.vn/san-pham/the-statue-of-liberty/?variation_id=23794",
            },
            {
              id: "a_17_10",
              style: {
                left: "46%",
                top: "41.5%",
              },
              link:
                "https://artisans.vn/san-pham/london-bridge/?variation_id=23760",
            },

            {
              id: "a_17_11",
              style: {
                left: "64.5%",
                top: "41.5%",
              },
              link:
                "https://artisans.vn/san-pham/buffaloes-in-field-2/?variation_id=34411",
            },

            // row product 3
            {
              id: "a_17_12",
              style: {
                left: "2%",
                bottom: "23.5%",
              },
              link:
                "https://artisans.vn/san-pham/gangster-dogs/?variation_id=33477",
            },
            {
              id: "a_17_13",
              style: { left: "46%", bottom: "23.5%" },
              link:
                "https://artisans.vn/san-pham/clorful-cats/?variation_id=33434",
            },
          ],
        },

        // Page 18
        {
          id: 18,
          name: "page18",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011918.jpg",
          quantityBtn: 0,
          listBtns: [
            {
              id: "a_18_1",
              style: { left: "7%", top: "39.5%" },
              link: "https://artisans.vn/san-pham/morella-stacking-armchair/?variation_id=6704",
            },
            {
              id: "a_18_2",
              style: { left: "25.5%", top: "28%" },
              link:
                "https://artisans.vn/san-pham/morella-round-table/?variation_id=6653 ",
            },
            {
              id: "a_18_3",
              style: {
                right: "11.5%",
                top: "27.5%",
              },
              link:
                "https://artisans.vn/san-pham/morella-square-table/?variation_id=14017",
            },
            {
              id: "a_18_4",
              style: {
                right: "0.5%",
                bottom: "20.5%",
              },
              link: "https://artisans.vn/san-pham/plantagoo/?variation_id=27861",
            },
          ],
        },

        // Page 19
        {
          id: 19,
          name: "page19",
          imgSrc:
            "https://artisans.vn/wp-includes/images/brochure/A4_Brochure%20Instore%20011520.jpg",
          quantityBtn: 0,
          listBtns: [],
        },
      ];
    },
  },
  methods: {
    getImgSrc(src) {
      console.log("src ", src);
      return require(src);
    },
  },
};
</script>

<style lang="scss">
#desktop-slide-container {
  .slick-arrow {
    height: 100%;
    width: 44px;
    z-index: 99;
  }
  .slick-prev,
  .slick-next {
    background-color: transparent;
    background-position: center;
    background-size: 44px 88px;
    background-repeat: no-repeat;
    transition: transform 0.3s;
  }

  .slick-prev {
    left: -44px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAABYCAYAAACONxXWAAAAAXNSR0IArs4c6QAAAg1JREFUaAXtmj1uwkAQhU2UNpVbyB0Q4iKUVICoULgCN0BQhDpngIsgcwfSuUkobAFx3gQsE9hihWalXfSQFi8re/z4/NifYaOILxIgARIgARIgARIIkEBRFBOURhDSIbSP8oWylbrXoiHwNcuyz+FwuB+Px/uz8Imt6CfbExXPe18sFvF6vX5O07SGuN8oH4rx9UKB5iBJkl273f6Rstlsdmjr6d1BMRKENfI833Y6nUOr1Srm83mGtqXiLXRDQdxqOp3mIlZEi3i01XXvohQNwmgFJZa3YWiFWyY6LSAbXq8wm80y9go6BqiiPIoV/JxGcoConKZfA92loVfw1gp9Thv1TXCKSCs4JBvcXIG9ghM3hDhXCN4KfqaaaAUnv7BzUNA1zRVoBRvo1tnLWk0SjVF0PB6lcvrw1+LhGyzRk0xjmXWUqSTaBh5KrSRB4FIyjlfLdj8n6CIbguuX6VLJRKJtVX0lD2sQSGs4fy7X1pCFJ63hAjuoBtdr/Fsi0RoubCExaQ1XZC/jgrJpQPFz+lkKfxRr+PvXrJCmNUq/uTyCsmlAoTXUobPXUEdqCAjKfcPilQOKgdX9TaH2GrTG/c/c8sqHsMZ5o53/vUb5n3S32z2AvOxfs055WWcvLZ+8zWlvo9EobTabhziOC1zwguI95XC245aPADYIZ8NzKZpHEiABEiABEnBO4BeqyRymDh9iywAAAABJRU5ErkJggg==");
  }

  .slick-next {
    right: -44px;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAABYCAYAAACONxXWAAAAAXNSR0IArs4c6QAAAiRJREFUaAXtmjFuwkAQRXGUNg1KCbkDQlyEkgoQDZLPwA0QFKHOGeAiyNyBMhQJhS2QnRkUm4DXGDG7sBv9SCubsT3+efuZNSNXKvgDARAAARAAARAAgTsRSJKkTmN0p9vJbkNCezTWNL54X5bN8NUkcMRCfd/fDQaDXRiGLPzN8G2V6Z+U0Xzwg0Lfm83GWy6Xz7PZ7JU+v+dPsyhCRLur1WrbarViHkEQbCnWt0hiXgoJnE+n07DZbCbtdnsfRRFbo54/05IIiauxSBbLosfjcUSxhSXy1DJIIKyhRqMxem6NyWQSwhoaAWepiKrbVQPWyOZS846L1qj/XVBgDc2OyNLBGhkKgztEWfWsYf0vFOcWFFXVmBucWHlqWEPOsDwDUYY1yjFpOOO/LCjWV42eoq+BBUWDg48pXK0aJ9Zgm9A/Yr81+CH/lpbXtd3L47zq2fPiOPY4lecdNnqymshC09/nzmfaBf21RNfEvcQ5z7903AmlmL31mMQtuON55t2amISJBLCCCappTlghJaF7S2RPHuCdqAq3rmi64ZXmQ1UoRSQ4gejOXbJCD88Kgtm+eCmscBGP4KCLCwSqgmDCiy+FFYrZyI8QXdUCYWd/AVaQT3hxBlihmI38CNF1foGwtyoQ3XWn0zm8r/bIH5PXdi+Z5Eu1Wk0ajcZ+OBx+0mdfbjKDGbg6MGUa9r+Om3Igse688JyKxhYEQAAEQAAEnCLwA7iIHKPYEg8PAAAAAElFTkSuQmCC");
  }
  .slick-prev::before,
  .slick-next::before {
    content: " ";
  }

  .slick-prev:hover,
  .slick-next:hover,
  .slick-prev:focus,
  .slick-next:focus {
    background-position: center;
    background-size: 44px 88px;
    background-repeat: no-repeat;
  }

  .slick-next:hover,
  .slick-next:focus {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAABYCAYAAACONxXWAAAAAXNSR0IArs4c6QAAAiRJREFUaAXtmjFuwkAQRXGUNg1KCbkDQlyEkgoQDZLPwA0QFKHOGeAiyNyBMhQJhS2QnRkUm4DXGDG7sBv9SCubsT3+efuZNSNXKvgDARAAARAAARAAgTsRSJKkTmN0p9vJbkNCezTWNL54X5bN8NUkcMRCfd/fDQaDXRiGLPzN8G2V6Z+U0Xzwg0Lfm83GWy6Xz7PZ7JU+v+dPsyhCRLur1WrbarViHkEQbCnWt0hiXgoJnE+n07DZbCbtdnsfRRFbo54/05IIiauxSBbLosfjcUSxhSXy1DJIIKyhRqMxem6NyWQSwhoaAWepiKrbVQPWyOZS846L1qj/XVBgDc2OyNLBGhkKgztEWfWsYf0vFOcWFFXVmBucWHlqWEPOsDwDUYY1yjFpOOO/LCjWV42eoq+BBUWDg48pXK0aJ9Zgm9A/Yr81+CH/lpbXtd3L47zq2fPiOPY4lecdNnqymshC09/nzmfaBf21RNfEvcQ5z7903AmlmL31mMQtuON55t2amISJBLCCCappTlghJaF7S2RPHuCdqAq3rmi64ZXmQ1UoRSQ4gejOXbJCD88Kgtm+eCmscBGP4KCLCwSqgmDCiy+FFYrZyI8QXdUCYWd/AVaQT3hxBlihmI38CNF1foGwtyoQ3XWn0zm8r/bIH5PXdi+Z5Eu1Wk0ajcZ+OBx+0mdfbjKDGbg6MGUa9r+Om3Igse688JyKxhYEQAAEQAAEnCLwA7iIHKPYEg8PAAAAAElFTkSuQmCC");
  }

  .slick-next:hover {
    transform: translate(2px, -50%);
  }

  .slick-prev:hover,
  .slick-prev:focus {
    transform: translate(-2px, -50%);
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAABYCAYAAACONxXWAAAAAXNSR0IArs4c6QAAAg1JREFUaAXtmj1uwkAQhU2UNpVbyB0Q4iKUVICoULgCN0BQhDpngIsgcwfSuUkobAFx3gQsE9hihWalXfSQFi8re/z4/NifYaOILxIgARIgARIgARIIkEBRFBOURhDSIbSP8oWylbrXoiHwNcuyz+FwuB+Px/uz8Imt6CfbExXPe18sFvF6vX5O07SGuN8oH4rx9UKB5iBJkl273f6Rstlsdmjr6d1BMRKENfI833Y6nUOr1Srm83mGtqXiLXRDQdxqOp3mIlZEi3i01XXvohQNwmgFJZa3YWiFWyY6LSAbXq8wm80y9go6BqiiPIoV/JxGcoConKZfA92loVfw1gp9Thv1TXCKSCs4JBvcXIG9ghM3hDhXCN4KfqaaaAUnv7BzUNA1zRVoBRvo1tnLWk0SjVF0PB6lcvrw1+LhGyzRk0xjmXWUqSTaBh5KrSRB4FIyjlfLdj8n6CIbguuX6VLJRKJtVX0lD2sQSGs4fy7X1pCFJ63hAjuoBtdr/Fsi0RoubCExaQ1XZC/jgrJpQPFz+lkKfxRr+PvXrJCmNUq/uTyCsmlAoTXUobPXUEdqCAjKfcPilQOKgdX9TaH2GrTG/c/c8sqHsMZ5o53/vUb5n3S32z2AvOxfs055WWcvLZ+8zWlvo9EobTabhziOC1zwguI95XC245aPADYIZ8NzKZpHEiABEiABEnBO4BeqyRymDh9iywAAAABJRU5ErkJggg==");
  }

  .slick-prev:hover {
    transform: translate(-2px, -50%);
  }

  .slick-slider {
    z-index: 2;
    border: 1px solid grey;
    border-radius: 3px;
  }
}
</style>
<style lang="scss" scoped>
@-webkit-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
#slide-wrapper {
  height: 100%;
  align-items: center;
  display: flex;

  .position-relative {
    position: relative;
  }
  .img-link {
    position: absolute;
    width: 11%;
    height: 9%;
    opacity: 0;
    // opacity: 1;
    // background-image: url("https://artisans.vn/wp-includes/images/brochure/loading.gif");
    // background-size: 20px;
    // background-repeat: no-repeat;
    // background-position: center;
  }
  .img-link:hover {
    opacity: 1;
    background-image: url("https://artisans.vn/wp-includes/images/brochure/loading.gif");
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }

  #mobile-slide-container {
    flex-grow: 1;
    width: 100%;
    display: none;
    @media screen and (max-width: 480px) {
      display: block;
    }

    .slider-item {
      text-align: center;

      img {
        display: inline-block;
        width: 100%;
        max-width: 100vw;
        max-height: 100%;
      }
    }

    #start-mobile {
      position: relative;
      &::before {
        z-index: 3;
        content: " ";
        background: url("https://artisans.vn/wp-content/themes/igitech/images/A5/0001.jpg");
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
        -webkit-animation: fade-out 9s;
        -moz-animation: fade-out 9s;
        -o-animation: fade-out 9s;
        animation: fade-out 9s;
        animation-fill-mode: forwards;
      }
    }
  }

  #desktop-slide-container {
    flex-grow: 1;
    width: 100%;
    max-width: 1046px;
    display: block;
    margin: 0 auto;
    border-radius: 3px;
    overflow: hidden;
    padding: 0 44px;
    position: relative;

    @media screen and (max-width: 480px) {
      display: none;
    }

    .slider-item {
      text-align: center;
      z-index: 2;
      img {
        display: inline-block;
        width: 100%;
        max-width: 100vw;
        max-height: 100%;
      }
    }

    #start {
      position: relative;
      &::before {
        z-index: 3;
        content: " ";
        background: url("https://artisans.vn/wp-includes/images/brochure/bia%20trang%20den.jpg");
        background-size: contain;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        opacity: 1;
        -webkit-animation: fade-out 9s;
        -moz-animation: fade-out 9s;
        -o-animation: fade-out 9s;
        animation: fade-out 9s;
        animation-fill-mode: forwards;
      }
    }
  }
}
</style>
